/* eslint-disable react/jsx-props-no-spreading */
import { useCallback } from 'react'
import { Experience } from '@ninetailed/experience.js-react'

import PricingCard from '@/components/ui/pricing-card'
import RichText from '@/components/ui/rich-text'

//@ts-ignore
import Spinner from '@/icons/bwi/spinner.svg'
import { mapNtExperiences } from '@/utils/ninetailed'
import { cx } from '@/utils/strings'
import { useLiveUpdates } from '@/utils/live-preview'
import type { Entry } from 'contentful'
import type { TypeSectionSetOfPricingSkeleton } from '@/types/ctf'

type SetOfPricingEntry = Entry<TypeSectionSetOfPricingSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>

type SetOfPricingProps = SetOfPricingEntry['fields'] & {
  hideHeadline?: boolean
  insideTabs?: boolean
}

export function SetOfPricingComponent({
  headline,
  body,
  hideHeadline,
  pricingCards,
  bottomRichText,
  bottomCallout,
  insideTabs,
  highlightTerms,
}: SetOfPricingProps) {
  return (
    <section className={`lg:px-9  max-w-8xl mx-auto ${!insideTabs && 'mb-10 lg:mb-20'}`}>
      <div className={cx('bg-primaryBlue lg:rounded-4xl', insideTabs ? `py-8` : `px-5 py-8 lg:px-9 lg:py-16`)}>
        <div className="text-white mb-12">
          {headline && !hideHeadline && <h3 className="text-center">{headline}</h3>}
          {body && (
            <div className="text-center max-w-3xl mx-auto">
              <RichText body={body} />
            </div>
          )}
        </div>
        {pricingCards && (
          <div
            className={cx(
              'xl:grid auto-cols-min mx-auto gap-6',
              pricingCards.length === 1 && `max-w-4xl grid-cols-1`,
              pricingCards.length === 2 && `max-w-4xl xl:max-w-6xl grid-cols-2`,
              pricingCards.length === 3 && `max-w-4xl xl:max-w-6xl grid-cols-3`,
              pricingCards.length === 4 && `grid-cols-4 max-w-8xl`
            )}
          >
            {pricingCards.map((pricingCard, idx) =>
              pricingCard?.fields?.columnHeading ? (
                <div
                  className={cx(
                    'hidden xl:block relative px-8 pb-[10px] text-2xl text-white text-center after:content after:block after:h-[15px] after:mb-1 after:border-r-2 after:border-l-2 after:border-t-2 after:border-t-white after:rounded-tl-full after:rounded-tr-full after:absolute after:-bottom-[15px] after:left-9 after:right-9',
                    pricingCard?.fields?.columnHeadingColumnSpan === '2' && 'col-span-2',
                    pricingCard?.fields?.columnHeadingColumnSpan === '3' && 'col-span-3',
                    pricingCard?.fields?.columnHeadingColumnSpan === '4' && 'col-span-4'
                  )}
                  key={`col-header-${idx}`}
                >
                  {pricingCard?.fields?.columnHeading}
                </div>
              ) : null
            )}
            {pricingCards.map((pricingCard, idx) =>
              pricingCard ? (
                <PricingCard key={`card-${idx}`} {...pricingCard.fields} highlightTerms={highlightTerms} />
              ) : null
            )}
          </div>
        )}
        <div className={cx('text-white [&_p]:text-sm max-w-2xl mx-auto text-center my-5', bottomCallout && `mb-8`)}>
          <RichText body={bottomRichText} />
        </div>
        {bottomCallout && (
          <div className="rounded-4xl max-w-6xl m-auto text-center p-8 bg-white [p]:m-0">
            <RichText body={bottomCallout} />
          </div>
        )}
      </div>
    </section>
  )
}

export default function SetOfPricing({ entryId, ...fields }: any): JSX.Element {
  const {
    fields: { nt_experiences, id, hideHeadline, insideTabs, ...props },
  } = useLiveUpdates({ fields, sys: { id: entryId } })

  const Loader = useCallback(
    () => (
      <div className="w-full flex justify-center items-center p-8 h-[700px]">
        <Spinner className="animate-spin h-20 w-20 text-gray-400" />
      </div>
    ),
    []
  )
  return (
    <Experience
      {...props}
      id={id}
      key={id}
      experiences={mapNtExperiences(nt_experiences)}
      component={SetOfPricingComponent}
      passthroughProps={{ insideTabs, hideHeadline }}
      loadingComponent={Loader}
    />
  )
}
