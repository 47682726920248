/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useLocation } from '@reach/router'

// import { graphql, navigate } from 'gatsby'
// import { localizedPath, getLocale } from '@plugins/bw-i18next/utils'
import { useState } from 'react'
import SetOfPricing from '@/components/section/set-of-pricing'
import { slugify } from '@/utils/strings'
import '@/components/ui/pricing-card'
import { cx } from '@/utils/strings'
import type { Entry } from 'contentful'
import type { TypeSectionTabSectionSkeleton } from '@/types/ctf'
import { useLiveUpdates } from '@/utils/live-preview'
import { usePage } from '@inertiajs/react'
import { getLocale, localizedPath } from '@/utils/locale'

type TabSectionEntry = Entry<TypeSectionTabSectionSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>

type TabSectionProps = TabSectionEntry['fields'] & {
  contentfulId: string
  allExperiences?: any
  allAudiences?: any
}

export default function TabSection({ contentfulId, isHero, ...fields }: TabSectionProps) {
  const {
    fields: { activeTab = 1, headline, internalName, tabs },
  } = useLiveUpdates({ fields, sys: { id: contentfulId } })

  const [activeTabIdx, setActiveTabIdx] = useState(activeTab ? activeTab - 1 : 0)

  const page = usePage()
  const pathname = page.url + '/'

  const handleSetActiveTab = (idx: number) => {
    if (idx === activeTabIdx) {
      return
    }

    const personalPricingPath = localizedPath('/pricing', getLocale(pathname))
    const businessPricingPath = localizedPath('/pricing/business', getLocale(pathname))
    console.log('personalPricingPath', personalPricingPath)
    console.log('pathname', pathname)
    if (pathname === personalPricingPath) {
      window.location.replace(businessPricingPath)
    } else if (pathname === businessPricingPath) {
      window.location.replace(personalPricingPath)
    }
    setActiveTabIdx(idx)
  }

  const Headline = isHero ? 'h1' : 'h3'

  return (
    <section className="max-w-8xl mx-auto lg:px-9 text-center" id={slugify(internalName)}>
      <div className="bg-primaryBlue lg:rounded-4xl py-20">
        {headline && <Headline className="h3 mx-3 mb-8 text-white">{headline}</Headline>}
        <div className="mx-auto">
          <div className="bg-deepBlue w-auto p-1 inline-block rounded-full transition-all" role="tablist">
            {tabs?.map((tab, idx) =>
              tab ? (
                <button
                  key={`tab-${contentfulId}-${idx}`}
                  data-testid={`tab-${contentfulId}-${idx}`}
                  id={`tab-${contentfulId}-${idx}`}
                  onClick={() => handleSetActiveTab(idx)}
                  tabIndex={idx === 0 ? 0 : -1}
                  type="button"
                  role="tab"
                  aria-selected={idx === activeTabIdx}
                  aria-controls={`tabpanel-${contentfulId}-${idx}`}
                  className={cx(
                    'py-4 px-8 lg:py-4 lg:px-12 transition-all',
                    idx === activeTabIdx ? `!bg-white rounded-full text-primaryBlue` : `text-white`
                  )}
                >
                  {tab.fields.headline || 'Missing Title!'}
                </button>
              ) : null
            )}
          </div>
          {tabs?.map((tab, idx) => {
            if (!tab) return null
            const { fields, sys } = tab
            return (
              <div
                role="tabpanel"
                aria-labelledby={`tab-${contentfulId}-${idx}`}
                key={`tabpanel-${contentfulId}-${idx}`}
                id={`tabpanel-${contentfulId}-${idx}`}
                data-testid={`tabpanel-${contentfulId}-${idx}`}
                className={cx('px-5 lg:px-0', idx === activeTabIdx ? `block` : `hidden`)}
              >
                <div className="-mt-6">
                  <SetOfPricing id={sys.id} {...fields} hideHeadline insideTabs client:only />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
